:root {
  --toast-p1: #4595d3;
  --toast-b4: #073358;
  --toast-a1: #81f244;
  --toast-a4: #ca2824;
  --toast-s1: #91a2af;
  --toast-height: 90px;
}

.Toastify__close-button {
  color: #fff;
  opacity: 1;
  padding-top: 10px;
}

.Toastify__toast {
  padding: 10px 16px;
  align-items: center;
  background: #0F0749;
  min-height: var(--toast-height);
}

.Toastify__toast-body {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 4px;
}

.Toastify__toast--success {
  border-width: 0;
}

.Toastify__toast--error {
  border-width: 0;
}

.Toastify__toast--info {
  border-width: 0;
}

.Toastify__progress-bar {
  height: 2px;
  background-color: #D501BB;
}

.Toastify__progress-bar--error {
  background: var(--toast-a4);
}
